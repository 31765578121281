exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentorship-js": () => import("./../../../src/pages/mentorship.js" /* webpackChunkName: "component---src-pages-mentorship-js" */),
  "component---src-pages-partials-mentorship-form-js": () => import("./../../../src/pages/partials/mentorshipForm.js" /* webpackChunkName: "component---src-pages-partials-mentorship-form-js" */),
  "component---src-pages-partials-reviews-js": () => import("./../../../src/pages/partials/reviews.js" /* webpackChunkName: "component---src-pages-partials-reviews-js" */),
  "component---src-pages-partials-socials-js": () => import("./../../../src/pages/partials/socials.js" /* webpackChunkName: "component---src-pages-partials-socials-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */)
}

